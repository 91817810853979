<template>
  <div class="admin-container">
    <template>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'ConstructionDiary',
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Záznam deníku</h3>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            :disabled="
              ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                ? false
                : true
            "
            v-model="data.name"
            label="Název záznamu *"
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="3">
          <DatePicker
            :disableUser="true"
            label="Termín záznamu *"
            @dateChanged="changeDate($event)"
            :initialDate="data.date_of_record"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-select
            :disabled="
              ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                ? false
                : true
            "
            v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
            :items="timelines"
            v-model="data.timeline_id"
            label="Přiřadit k etapě *"
            item-text="name"
            item-value="id"
          ></v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            :disabled="
              ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                ? false
                : true
            "
            v-model="data.note"
            label="Poznámka"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p class="col-secondary-3" style="font-size: 14px">
            Projektový manager: {{ data.project_manager.full_name }}
          </p>
        </v-col>
      </v-row>

      <v-btn
        x-small
        height="38"
        class="mobile-width-send-message mt-8"
        tile
        @click="download"
        >Stáhnout vše</v-btn
      >
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline">Odstranit dokument</v-card-title>
            <v-card-subtitle
              >Opravdu si přejete odstranit dokument ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    small
                    tile
                    width="100%"
                    @click="handleDeleteFile"
                    >Odstranit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="dialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <template v-if="!hasQueryParam">
        <input
          style="display: none"
          ref="documentFile"
          type="file"
          class="input--file"
          multiple
          @input="handleInputFile($event.target.files)"
        />
        <div
          class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
        >
          <div
            v-for="(document, documentIdx) in data.documents"
            :key="document.document_id"
          >
            <div
              class="document-added mr-md-6 cursor-pointer"
              @click.stop="downloadDoc(document.document_id)"
            >
              <div
                class="document-document-mini"
                :style="correctIcon(document)"
              ></div>
              <div class="document-extension">
                {{ document.extension ? document.extension.toUpperCase() : '' }}
              </div>
              <div class="document-text document-text--overflow mt-2"></div>
              <div
                class="document-close"
                v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
              >
                <button
                  @click.stop="
                    (focusedId = document.document_id),
                      (focusedIdx = documentIdx),
                      (dialog = true)
                  "
                >
                  <img :src="require('@/assets/zavrit.svg')" alt="" />
                </button>
              </div>
            </div>
            <v-text-field
              :disabled="
                ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                  ? false
                  : true
              "
              style="width: 150px"
              v-model="document.document_name"
              label="Název souboru *"
            ></v-text-field>
          </div>

          <button
            class="document-input-add"
            @click="handleClick('documentFile')"
            v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
          >
            <div class="document-input-add-plus">+</div>
            <div class="document-input-add-text">Vložit dokument</div>
          </button>
        </div>
      </template>
      <template v-if="hasQueryParam">
        <input
          style="display: none"
          ref="documentFile"
          type="file"
          class="input--file"
          capture="camera"
          @input="handleInputFile($event.target.files[0])"
        />
      </template>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            v-if="user.role === 'SUPERUSER'"
            color="error"
            class="mr-4"
            small
            tile
            @click="deleteDialog = true"
            >Odstranit</v-btn
          >
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="
              $router.push({
                name: 'ConstructionDiary',
              })
            "
            >Zrušit</v-btn
          >
          <v-btn
            small
            tile
            color="primary"
            v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
            @click="update"
            >Uložit</v-btn
          >
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="deleteDialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline"
              >Odstranit stavební deník</v-card-title
            >
            <v-card-subtitle
              >Opravdu si přejete odstranit stavební deník ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn
                    color="error"
                    small
                    tile
                    width="100%"
                    @click="handleDeleteConstructionDiary"
                    >Odstranit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="deleteDialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import timelineService from '../api/timelineService';
import constructionDiaryService from '../api/constructionDiaryService';
import downloadService from '../api/downloadService';
import DatePicker from '../components/DatePicker';
const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      hasQueryParam: false,
      focusedId: null,
      focusedIdx: null,
      dialog: false,
      data: {},
      documents: [],
      timelines: [],
      deleteDialog: false,
    };
  },
  async created() {
    // permissionDenied(this.user, this.$router, "SUPERUSER", "PROJECT_MANAGER");
    bus.$emit('processing');
    await this.indexTimelines();
    await this.show();
    if (this.$route.query.fileType) {
      this.hasQueryParam = true;
      setTimeout(() => this.handleHasQueryParam(), 120);
    }
    bus.$emit('processing', false);
  },
  components: {
    DatePicker,
  },
  methods: {
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    async handleDeleteConstructionDiary() {
      try {
        bus.$emit('processing', true);
        await constructionDiaryService.destroy(this.$route.params.id);
        this.$router.push({ name: 'ConstructionDiary' });
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit('processing');
        await documentService.show(id);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    changeDate(v) {
      this.data.date_of_record = v;
    },
    async download(id) {
      try {
        const { data } = await constructionDiaryService.download(
          this.$route.params.id
        );
        await downloadService.downloadFromCode(data.code);
        console.debug(data);
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    async show() {
      try {
        const data = await constructionDiaryService.show(this.$route.params.id);
        this.data = data;
      } catch (error) {
        console.error(error);
        errorHandler(erro);
      }
    },
    async handleDeleteFile() {
      try {
        bus.$emit('processing');
        await documentService._delete(this.focusedId);
        this.data.documents.splice(this.focusedIdx, 1);
        bus.$emit('processing', false);
        this.dialog = false;
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f) {
      try {
        console.debug(f);
        bus.$emit('processing');
        for (let fidx = 0; fidx < f.length; fidx++) {
          const { data } = await documentService.store(f[fidx]);
          this.data.documents.push({
            document_id: data.id,
            document_name: data.client_name,
            mimetype: data.mimetype,
            mime: data.mime,
            extension: data.extension,
          });
        }
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
        bus.$emit('processing', false);
        this.$refs.documentFile.values = [];
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async indexTimelines() {
      try {
        const data = await timelineService.index();
        this.timelines = data;
      } catch (error) {
        console.error(error);
        errorHandler(error);
      }
    },
    async update() {
      try {
        bus.$emit('processing');
        const response = await constructionDiaryService.update(
          this.$route.params.id,
          {
            ...this.data,
          }
        );
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'ConstructionDiary',
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
